
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>配件列表</el-breadcrumb-item>
      <el-breadcrumb-item>分类列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    内容开始-->
    <el-card class="box-card">
      <el-button type="primary" @click='opentan()'>添加信息</el-button>
      <!--    列表开始-->
      <el-table
        :data="tableData"
        stripe
        row-key="id"
        style="width: 100%">
        <el-table-column
          prop="c_name"
          label="分类名称"
        >
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click='editfun(scope.row)'></el-button>
            <el-button size="mini" type="success" icon="el-icon-view" @click='lookfun(scope.row.id)'></el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click='delfun(scope.row.id)'></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页-->
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!--  弹窗内容-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-form ref="dform" :model="dform" label-width="120px" :rules="drules">
        <el-form-item label="分类名称" prop="c_name">
          <el-input v-model="dform.c_name"></el-input>
        </el-form-item>
        <el-form-item v-if="tantype==='add'"  label="所属级别" prop='type'>
          <el-radio-group v-model="dform.type">
            <el-radio :label="1">产品种类</el-radio>
            <el-radio :label="0">一二级类型</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="隶属上级" prop='p_id'>
          <el-cascader
            v-model="p_idarr"
            :options="tableData"
            :props="cateListProps"
            @change="handfuleChange"></el-cascader>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="cancelfun()">取 消</el-button>
    <el-button v-if="tantype!=='look'" type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import {
  AddPartscategory,
  DelPartscategory,
  EditPartscategory,
  GetPartscategoryList,
  GetOnePartscategory, GetEquipmentList
} from '../../../api/vehicle/autoconfigure'

export default {
  components: {
    Fenye
  },
  data() {
    return {
      dialogVisible: false, // 弹窗控制
      loading: false,
      p_idarr:[],
      dform: {
        c_name: '',
        type: 1,
        p_id: '', // 父级id
        e_id: '' // 品牌ID
      },
      // 级联选择配置
      cateListProps: {
        checkStrictly:true,
        value: 'id', // 匹配响应数据中的id
        label: 'c_name', // 匹配响应数据中的name
        children: 'children' // 匹配响应数据中的children
      },
      // 选项
      optionsdata: [

      ],
      // 父级id选择
      optionsfu: [],
      // 弹窗
      // 按钮的状态
      tantype: 'add',
      // 验证规则
      drules: {
        c_name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]

      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      // 列表数据
      tableData: []
    }
  },
  mounted() {
    this.getpartscategorylistfun()
  },
  methods: {
    bianzhi(newSize) { // 分页值变化
      this.pageData.size = newSize
      this.getpartscategorylistfun()
    },
    bianye(newPape) { // 分页页数变化
      this.pageData.page = newPape
      this.getpartscategorylistfun()
    },
    editfun(ev) { // 打开编辑
      // 赋值
      this.dform = JSON.parse(JSON.stringify(ev))
      // 修改属性为修改
      this.tantype = 'edit'
      // 打开弹窗
      this.opentan()
      this.p_idarr.push(this.dform.pid)

    },
    lookfun(ld) { // 打开查看
      console.log(ld)
      this.onepartscategoryfun(ld)
      // 修改属性为修改
      this.tantype = 'look'
      // 打开弹窗
      this.opentan()
    },
    delfun(id) { // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delpartscategoryfun(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true
    },
    // 父级选中的值
    handfuleChange(e) {
      console.log(e)
      this.dform.p_id = e[e.length-1]
    },
    // 确定添加
    addgoto(type) {
      switch (type) {
        case 'add':
          console.log(type)
          this.$refs['dform'].validate(v => {
            if (v) {
              this.addpartscategoryfun(this.dform)
            }
          })
          break
        case 'edit':
          this.$refs['dform'].validate(v => {
            if (v) {
              this.editpartscategoryfun(this.dform)
            }
          })
          console.log(type)
          break
      }
    },

    // 取消
    cancelfun() {
      this.handleClose()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {}
      this.p_idarr =[]
      this.tantype = 'add'
    },
    // 请求区ლ(´ڡ`ლ)

    // 获取分类
    async getpartscategorylistfun() {
      const { data } = await GetPartscategoryList(this.pageData)
      this.getanpostfun(data, '获取')
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },
    // 添加分类
    async addpartscategoryfun(v) {
      const { data } = await AddPartscategory(v)
      this.getanpostfun(data, '添加', this.getpartscategorylistfun())
    },
    // 删除分类
    async delpartscategoryfun(id) {
      const { data } = await DelPartscategory({ id })
      this.getanpostfun(data, '删除', this.getpartscategorylistfun())
    },
    // 修改
    async editpartscategoryfun(ev) {
      const { data } = await EditPartscategory(ev)
      this.getanpostfun(data, '修改', this.getpartscategorylistfun())
    },
    // 查看
    async onepartscategoryfun(id) {
      const { data } = await GetOnePartscategory({ id })
      this.dform = data.data.category_Info
      this.p_idarr.push(data.data.category_Info.pid)
    },

    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        this.handleClose()
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
<style scoped>

</style>
